import { RouteParams } from "@router/route-params";

/** Validates that the passed value has valid URL format */
export function isValidUrl(value: unknown): value is string {
  if (typeof value !== "string") {
    return false;
  }

  try {
    new URL(value);
    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Returns whether the passed host is a numbered environment.
 *
 * @param host E.g. "dev1.sphere.dev.holobuilder.com" or "sphere.holobuilder.com"
 * @returns True if the host is a numbered environment, e.g. "dev1.sphere.dev.holobuilder.com"
 */
export function isHostNumberedEnv(host: string): boolean {
  return /^dev\d{1,2}/.test(host);
}

/**
 * Returns the numbered environment from the passed host.
 *
 * @param host E.g. "dev1.sphere.dev.holobuilder.com" or "sphere.holobuilder.com"
 * @returns The numbered environment if the host is a numbered environment, e.g. "dev1"
 */
export function getNumberedEnv(host: string): string | null {
  const numberedEnv = host.match(/^dev\d{1,2}/);
  return numberedEnv?.length ? numberedEnv[0] : null;
}

/**
 * Adds the numbered environment to the URL if it is running a numbered environment,
 * and if the URL has numbered environments, like dev1.sphere.holobuilder.com.
 *
 * @param url The original URL to add the numbered environment to.
 * @param numberedEnv The numbered environment to add to the URL.
 * @returns URL with the numbered environment added.
 */
export function addNumberedEnv(
  url: string,
  numberedEnv?: string | null
): string {
  if (!numberedEnv) {
    return url;
  }

  const urlObj = new URL(url);

  // Add numbered environment only to sphere.*.holobuilder.com or sphere.*.eu URLs
  if (urlObj.hostname.match(/sphere.*\.holobuilder\.(com|eu)/)) {
    const parts = urlObj.hostname.split(".");
    parts[0] = `${numberedEnv}.${parts[0]}`;
    urlObj.hostname = parts.join(".");
  }

  return urlObj.toString();
}

/** Gets the URL for the Dashboard 2.0 API, including the numbered environment */
export function getDashboard20ApiUrl(
  dashboard20Api: string,
  numberedEnv: string | null
): string {
  return addNumberedEnv(dashboard20Api, numberedEnv);
}

/**
 * Returns the pathname from the URL with the ids replaced by the key names.
 *
 * @param url A URL with ids in the path, e.g. /project/1234
 * @param appParams An object with the key names and their corresponding ids, e.g. { projectId: 1234 }
 * @returns The masked pathname, e.g. /project/:projectId
 */
export function getPathnameFromUrl(
  originalPathname: string,
  appParams: Partial<RouteParams>
): string {
  let pathname = originalPathname;
  let key: keyof typeof appParams;
  for (key in appParams) {
    // Replace all ids in the path with the key name
    // e.g. /project/1234 -> /project/:projectId
    if (key in appParams && key.endsWith("Id")) {
      pathname = pathname.replace(`${appParams[key]}`, `:${key}`);
    }
  }

  return pathname;
}
